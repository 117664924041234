<template>
  <v-text-field
    v-if="obCustomer"
    :label="$t(sLabel)"
    :loading="loading"
    :value="obCustomer.firm.name"
    dense
    disabled
    hide-details="auto"
    no-filter
    outlined
  >
    <template v-if="isTemporary" #append>
      <v-btn
        :loading="loading"
        :title="sTitle"
        color="primary"
        x-small
        @click="onGenerate"
      >
        {{ $t("create") }}
      </v-btn>
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Customer, CustomerData } from "@planetadeleste/vue-mc-gw";
import { get } from "lodash";
import { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";

@Component
export default class CustomerFieldPreview extends Vue {
  @Prop(Object) readonly item!: CustomerData;
  @Prop([String, Number]) readonly itemId!: number;
  @Prop(Boolean) readonly notLoad!: boolean;

  obCustomer: Customer | null = null;
  loading = false;

  get isTemporary() {
    return this.obFirm && this.obFirm.name === this.obFirm.doc_id;
  }

  get obFirm() {
    return this.obCustomer?.firm;
  }

  get sLabel() {
    return this.obCustomer?.is_provider ? "provider" : "customer";
  }

  get sTitle() {
    return this.$t(
      `create.${this.obCustomer?.is_customer ? "customer" : "provider"}`
    );
  }

  async mounted() {
    if (this.item && this.notLoad) {
      this.obCustomer =
        this.item instanceof Customer ? this.item : new Customer(this.item);
      return;
    }

    const obModel = new Customer();
    const sId = this.itemId ?? get(this.item, "id");

    if (sId) {
      obModel.set("id", sId);
      await obModel.fetch();
      this.obCustomer = obModel.clone() as Customer;
    }
  }

  async onGenerate() {
    if (!this.obCustomer || !this.obCustomer.id) {
      return;
    }

    this.loading = true;
    const obData = { id: this.obCustomer.id };
    const obConfig: AxiosRequestConfig = {
      url: route("customers.generate", obData),
      method: "POST",
      data: obData,
    };
    const obModel = new Customer();
    const obResponse = await obModel.createRequest(obConfig).send();

    if (obResponse.getData()) {
      const obModelData = obResponse.getData().data as Partial<CustomerData>;
      this.obCustomer.set(obModelData);
    }

    this.loading = false;
  }
}
</script>
